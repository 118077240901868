<div class="wrap">
    <img class="avatar" src="assets/images/avatar.jpg" alt="avatar">
    <div class="main-text">
        <h1 class="main-text__title">Hi, I'm Vadim Petrov — a frontend developer and web-designer from Moscow</h1>
        <p class="main-text__description">
            If you want to ask me anything, feel free to use the links below.
        </p>
    </div>
    <div class="social-links">
        <ul class="social-links__list">
            <li class="social-links__item" *ngFor="let link of socialLinks">
                <a class="social-links__item-link" [attr.href]="link.href" target="_blank">
                    <span class="social-links__item-icon">{{ link.icon }}</span>
                    <span class="social-links__item-name">{{ link.name }}</span>
                </a>
            </li>
        </ul>
    </div>
</div>